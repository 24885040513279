import { api, apiRoutes } from './api.service';
import { ImportData, ImportResponseData } from 'components/ImportButton';
import { FieldEditData } from 'components/ExtractedPreview';
import { ImportResult, ImportResultData } from 'state/types';
import { DocumentData } from '../components/Documents/types';

export const getFileExport = async (id: number) => {
    return await api.fetchWithHeaders<JSONValue>(`${apiRoutes.document}/${id}/export`);
};

export const getDocumentFile = async (id: number) => {
    return await api.getFileResponse(`${apiRoutes.document}/download/${id}`);
};

export const deleteDocument = async (id: number) => {
    return await api.fetch<void>(`${apiRoutes.documents}/${id}`, 'delete');
};

export const deleteDocumentsBulk = async (ids: number[]) => {
    return await api.fetch<void>(`${apiRoutes.document}?orderIds=${ids.toString()}`, 'delete');
};

export const uploadDocument = async (data: ImportData) => {
    const formData = new FormData();

    if (data.files?.length) {
        data.files.forEach(file => {
            formData.append('files', file);
        });
    }
    const templateId = data.template || -1;

    return await api.fetch<ImportResponseData>(
        `${apiRoutes.documentsUpload}/${templateId}`,
        'post',
        formData,
        { 'Content-Type': 'multipart/form-data' }
    );
};

export const reImportWithTemplateId = async (orderId: number, templateId: number) => {
    return await api.fetch<DocumentData[]>(
        `${apiRoutes.documentsMatch}?orderId=${orderId}&templateId=${templateId}`,
        'post'
    );
};

export const importDocuments = async (orderId: number) => {
    return await api.fetch<ImportResult>(`${apiRoutes.documentsImport}/${orderId}`, 'post');
};

export const editDocument = async (orderId: number, data: FieldEditData[]) => {
    return await api.fetch<void>(`${apiRoutes.document}/${orderId}`, 'put', data);
};

export const getImportResults = async (ids: number[]) => {
    return await api.fetch<ImportResultData[]>(
        `${apiRoutes.documentsImportResult}?orderIds=${ids.toString()}`
    );
};

export const downloadDocuments = async (ids: number[]) => {
    return await api.fetch<any>(`${apiRoutes.documentsDownload}?orderIds=${ids.toString()}`);
};

export const removeLine = async (id: number, lineIndex: number) => {
    return await api.fetch<any>(`${apiRoutes.document}/${id}/remove-line/${lineIndex}`, 'put');
};
