import { saveAs } from 'file-saver';

import { getDocumentFile, getFileExport, getTemplateDocumentFile } from 'services';

export const downloadImportData = async (id: number) => {
    const response = await getFileExport(id);

    if (!response) {
        return;
    }

    const filename: string = response.headers.filename;

    let data = filename.endsWith('.json') ? JSON.stringify(response.data, null, 2) : response.data;
    data = filename.endsWith('.csv') ? '\ufeff' + data : data;
    const blob = new Blob([data as string], {
        type: 'text/plain',
    });

    saveAs(blob, filename);
};

export const downloadDocument = async (id: number, isTemplate: boolean) => {
    const response = isTemplate ? await getTemplateDocumentFile(id) : await getDocumentFile(id);

    if (!response) {
        return;
    }

    const filename: string = response.headers.filename;

    const blob = new Blob([response.data as Blob], {
        type: 'application/pdf',
    });

    saveAs(blob, filename);
};
